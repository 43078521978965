import React, { useEffect, useState, useRef } from 'react'

import {
  clientsAtom,
  getListOfClients,
  updateClientGroups,
  updateClientLocation,
  getTimePast,
  deleteClient,
  rebootClient
} from '../../states/client'
import { useRecoilState } from 'recoil'

import { useNavigate } from "react-router-dom";

import LoadingBars from '../../components/Loading/LoadingBars';

import { Formik, Form, Field } from 'formik';

import Loading from '../../components/Loading/Loading';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'

import '../../components/GenericPage/page.css'
import './clients.css'
import { endpoint } from '../../states/auth'

library.add(fas)

const ClientsComp = ({ setPageName }) => {

  const history = useNavigate();

  const [clients, setClients] = useRecoilState(clientsAtom)

  const searched = window.location.search?.split('?')[1]?.split('_') || []
  const searchedClientID = searched[0] ? searched[0] : null
  const searchedTab = searched[1] ? searched[1] : null
  const currentClient = clients.find(item => item.id === searchedClientID)

  const [selectedClientTab, setSelectedClientTab] = useState(searchedTab)

  const [selectedGroup, setSelectedGroup] = useState('All')
  const clientGroups = ['All', ...new Set(clients.map(item => { return item.Group }))]
  const [selectedClient, setSelectedClient] = useState(currentClient ? currentClient : null)
  const [serverLogs, setServerLogs] = useState(currentClient ? currentClient.ServerLogs : [])
  const [clientLogs, setClientLogs] = useState(currentClient ? currentClient.ClientLogs : [])
  const [logRefresh, setLogRefresh] = useState(true)

  const [deletingClient, setDeletingClient] = useState(false)

  const [clientSearch, setClientSearch] = useState('')

  const selectGroup = (value) => {
    if (value !== selectedGroup) {
      setSelectedGroup(value)
    }
  }

  const selectClient = (client) => {
    if (selectedClient) {
      if (client.id !== selectedClient.id) {
        setSelectedClient(client)
        setClientLogs(client.ClientLogs)
        setServerLogs(client.ServerLogs)
        setSelectedClientTab(null)
      }
    } else {
      setSelectedClient(client)
      setClientLogs(client.ClientLogs)
      setServerLogs(client.ServerLogs)
      setSelectedClientTab(null)
    }
    history(`${window.location.pathname}?${client.id}`)
  }

  const filterClients = (c = clients) => {
    if (selectedGroup === 'All') {
      return c
    }
    return clients.filter(item => item.Group === selectedGroup)
  }

  const searchClients = (c = clients) => {
    return c.filter(item => item.Name.toLowerCase().includes(clientSearch) ||
      item.Group.toLowerCase().includes(clientSearch) ||
      item.Location.toLowerCase().includes(clientSearch) ||
      getTimePast(item.LastConnect).includes(clientSearch) ||
      item.Version.toLowerCase().includes(clientSearch) ||
      item.IP.toLowerCase().includes(clientSearch) ||
      item.id.toLowerCase().includes(clientSearch))
  }

  const updateClients = async () => {
    const newClients = await getListOfClients()
    if (selectedClient) {
      let selected = newClients.find(item => item.id === selectedClient.id)
      if (selected) {
        setSelectedClient(selected)
        if (logRefresh) {
          setClientLogs(selected.ClientLogs)
          setServerLogs(selected.ServerLogs)
        }
      } else {
        setSelectedClient(null)
      }
    }
    setClients(newClients)
  }

  const submitForm = async (values, client) => {
    if (values.group !== client.Group) {
      if (values.group.trim().length === 0) {
        await updateClientGroups(values.id, 'Unsorted')
      } else {
        await updateClientGroups(values.id, values.group.trim())
      }
    }

    if (values.location !== client.Location) {
      await updateClientLocation(values.id, values.location.trim())
    }

    window.location.reload()
    return
  }

  const updateVal = () => {
    if (val === 0) {
      setVal(1)
    } else {
      setVal(0)
    }
  }

  const [val, setVal] = useState(0)

  useEffect(() => {
    const timer = setTimeout(updateVal, 1000 * 10) // update very 10 seconds
    updateClients()
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  useEffect(() => {
    setPageName('Clients');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {deletingClient && (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          zIndex: '2000',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          width: '100%',
          top: '0',
          left: '0',
          height: '100vh'
        }}>
          <Loading color={'white'} />
        </div>)}
      <div className='pageBody' style={{ maxWidth: '700px' }}>
        <div className='pageLayoutBoxes'>
          <div className='clientInformation' style={{ color: '#eeeeee' }}>
            {selectedClient && (
              <Client
                client={selectedClient}
                serverLogs={serverLogs}
                clientLogs={clientLogs}
                logRefresh={logRefresh}
                selectedClientTab={selectedClientTab}
                setSelectedClientTab={setSelectedClientTab}
                setLogRefresh={setLogRefresh}
                submitForm={submitForm}
                setDeletingClient={setDeletingClient}
              />
            )}
          </div>
          <div className='clientBox'>
            <div className='clientGroupSelection'>
              <h3
                style={{
                  color: '#eeeeee',
                  textDecoration: 'underline',
                  marginLeft: '10px',
                  marginBottom: '5px',
                  paddingTop: '5px'
                }}>
                Groups:
              </h3>
              {clientGroups.map((item, index) => {
                return (
                  <p
                    key={index}
                    className='clientGroupSelectionItem'
                    style={selectedGroup === item ? { color: '#3aa3f5', cursor: 'default' } : {}}
                    onClick={(e) => {
                      e.preventDefault()
                      selectGroup(item)
                    }}
                  >
                    {item}
                  </p>
                )
              })}
            </div>
            <div className='clientSelection'>
              <div className='clientSelectionFilter'>
                <h3
                  style={{
                    color: '#eeeeee',
                    marginLeft: '5px',
                  }}>
                  Clients:
                </h3>
                <input
                  className='clientListSearchField'
                  type="text"
                  placeholder='Search...'
                  value={clientSearch}
                  onChange={(e) => setClientSearch(e.target.value.toLowerCase())}
                />
              </div>
              <div className='clientSelectionScrollable'>
                {clients.length > 0 ? <>
                  {searchClients(filterClients(clients)).length > 0 ? <>
                    {searchClients(filterClients(clients)).map(client => {
                      return (
                        <div
                          className='clientSelectionOption'
                          style={selectedClient?.id === client.id ? {
                            color: '#f4f4f4',
                            borderBottom: '1px solid #2b7fbe'
                          }
                            :
                            {}}
                          key={client.id}
                          onClick={(e) => {
                            e.preventDefault()
                            selectClient(client)
                          }}
                        >
                          <h4>
                            {client.Name}
                          </h4>
                          <div style={{
                            display: 'flex',
                            fontSize: '11px',
                            paddingTop: '5px'
                          }}>
                            <p style={{ borderRight: '1px solid #444444', flex: 1 }}>
                              IP: {client.IP.split(' ')[0]}<br />
                              Connected: {getTimePast(client.LastConnect)} ago
                            </p>
                            <p style={{ paddingLeft: '5px', flex: 1 }}>
                              {client.Location === '' ? `Group: ${client.Group}` : `Location: ${client.Location}`}<br />
                              Version: {client.Version}
                            </p>
                          </div>
                        </div>
                      )
                    })}
                  </> :
                    <p style={{ textAlign: 'center', color: '#dddddd', margin: '10px 0px' }}>No Clients Matching Search.</p>
                  }
                </>
                  :
                  <p style={{ textAlign: 'center', color: '#dddddd', margin: '10px 0px' }}>No Clients.</p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Clients = ({ setPageName }) => {
  return (
    <React.Suspense fallback={<Loading color={'white'} />}>
      <ClientsComp setPageName={setPageName} />
    </React.Suspense>
  )
}

export default Clients

const Client = ({ client, serverLogs, clientLogs, logRefresh, setLogRefresh, submitForm, selectedClientTab, setSelectedClientTab, setDeletingClient }) => {
  const [toggleTimestamp, setToggleTimestamp] = useState(true)
  const initialValues = {
    group: client.Group,
    location: client.Location,
    id: client.id
  }

  const history = useNavigate();

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView({ block: "nearest", inline: "nearest" }));
    return <div ref={elementRef} />;
  };

  const clientTabSelection = (tab) => {
    if (tab !== selectedClientTab) {
      history(`${window.location.pathname}?${client.id}_${tab}`)
      setSelectedClientTab(tab)
    }
  }

  const submitDeleteClient = async () => {
    setDeletingClient(true)
    await deleteClient(client.id)
    setDeletingClient(false)
    window.location = '/Clients'
  }

  const submitRebootClient = async () => {
    setDeletingClient(true)
    await rebootClient(client.id)
    setDeletingClient(false)
    window.location.href = `${window.location.pathname}?${client.id}`
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex', height: '100%', color: '#eeeeee' }}>


        <div style={{ flex: '3', borderRight: '1px solid #aaaaaa', height: '100%', padding: '0px 5px', overflow: 'auto' }}>
          <h2 className='clientSelectionItemName'><strong>{client.Name}</strong></h2>

          
          {(client.screenshot) && 
          <div
          className='clientSelectionItem clientSelectionItemSpacing'
          style={{ flexWrap: 'wrap' }}
          >
          <div >
                  <span className= 'clientSelectionItemHeader'>Screenshot:</span>
                  <div className='clientInfoImageOutline'>
                      <DisplayImage src={`${endpoint}${client.screenshot}`} />
                  </div>
                  <p style={{ fontSize: '10px' }}>Last Updated: {getTimePast(client.screenshotUpdated)} ago</p>
            </div></div>}
          <div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>IP:</p>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {client.IP.split(' ').map((item, index) => {
                return (
                  <div key={index} style={{ display: 'flex' }}>
                    {index !== 0 && (
                      <div style={{ borderRight: '1px solid #206294', marginRight: '5px', paddingLeft: '5px' }} />
                    )}
                    <p>{item}</p>
                  </div>
                )
              })}
            </div>
          </div>

          <div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Last Connect:</p>
            <p>
              {getTimePast(client.LastConnect)} ago
            </p>
          </div>

          <div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Version:</p>
            <p>
              {client.Version}
            </p>
          </div>

          <div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Location:</p>
            <p>
              {client.Location ? client.Location : 'Not Set'}
            </p>
          </div>

          <div
            className='clientSelectionItem'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Group:</p>
            <p>
              {client.Group}
            </p>
          </div>



        </div>
        <div className='clientSelectionTabs'>
          <button
            className='clientSelectionIcon'
            style={selectedClientTab === 'edit' ? { color: '#3aa3f5', borderColor: '#2b7fbe' } : {}}
            onClick={(e) => clientTabSelection('edit')}
          >
            <FontAwesomeIcon icon="fa-solid fa-pencil" />
          </button>

          <button id='clientSelectionIconServer' className='clientSelectionIcon'
            style={selectedClientTab === 'server' ? { color: '#3aa3f5', borderColor: '#2b7fbe' } : {}}
            onClick={(e) => clientTabSelection('server')}
          >
            <FontAwesomeIcon icon="fa-solid fa-server" />
          </button>


          <button id='clientSelectionIconClient' className='clientSelectionIcon'
            style={selectedClientTab === 'client' ? { color: '#3aa3f5', borderColor: '#2b7fbe' } : {}}
            onClick={(e) => clientTabSelection('client')}
          >
            <FontAwesomeIcon icon="fa-solid fa-terminal" />
          </button>

          <button id='clientSelectionIconReboot' className='clientSelectionIcon'
            style={selectedClientTab === 'reboot' ? { color: '#3aa3f5', borderColor: '#2b7fbe' } : {}}
            onClick={(e) => clientTabSelection('reboot')}
          >
            <FontAwesomeIcon icon="fa-solid fa-power-off" />
          </button>

          <button id='clientSelectionIconDelete' className='clientSelectionIcon'
            style={selectedClientTab === 'delete' ? { color: '#3aa3f5', borderColor: '#2b7fbe' } : {}}
            onClick={(e) => clientTabSelection('delete')}
          >
            <FontAwesomeIcon icon="fa-solid fa-trash" />
          </button>
        </div>
        <div style={{ flex: '5', overflow: 'auto' }}>
          {selectedClientTab === 'edit' && (
            <div>
              <Formik
                initialValues={initialValues}
                validate={(values) => { return {} }}
                onSubmit={async (values, { resetForm }) => {
                  await submitForm(values, client)
                }}
              >
                {({ isSubmitting, values }) => (
                  <Form>
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                      <p style={{ paddingLeft: '5px', paddingTop: '5px' }}>Group:</p>
                      <Field
                        className='clientListSearchField'
                        style={{ marginLeft: '5px' }}
                        type='text'
                        placeholder={client.Group}
                        name='group'
                      />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                      <p style={{ paddingLeft: '5px' }}>Location:</p>
                      <Field
                        className='clientListSearchField'
                        style={{ marginLeft: '5px' }}
                        type='text'
                        placeholder={client.Location}
                        name='location'
                      />
                    </div>
                    <button
                      className='clientListSearchField'
                      type='submit'
                      disabled={isSubmitting}
                      style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '5px' }}
                    >
                      {isSubmitting ?
                        <div style={{ textAlign: 'center', width: '46px' }}><LoadingBars /></div>
                        :
                        'Submit'
                      }
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          )}

          {selectedClientTab === 'server' && (
            <div className='clientLogBox'>
              <div style={{ borderBottom: '1px solid #acacac', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <p>Server Logs:</p>
                <div style={{ display: 'flex', fontSize: '12px' }}>
                  <input
                    style={{ cursor: 'pointer' }}
                    checked={logRefresh}
                    onChange={(e) => setLogRefresh(e.target.checked)}
                    type='checkbox'
                  />
                  <p style={{ margin: '3px 5px 0px 2px' }}>Refresh</p>
                  <input
                    checked={toggleTimestamp}
                    style={{ cursor: 'pointer' }}
                    onChange={(e) => setToggleTimestamp(e.target.checked)}
                    type='checkbox'
                  />
                  <p style={{ margin: '3px 5px 0px 2px' }}>Timestamps</p>
                </div>
              </div>
              <div style={{ fontSize: '11px', overflow: 'auto', height: 'calc(100% - 16px)' }}>
                {serverLogs.map((log, index) => {
                  return (
                    <div key={index}>
                      <p>{toggleTimestamp &&
                        (<span style={{ color: '#3aa3f5' }}>{log.timestamp}: </span>)}
                        {log.log}
                      </p>
                    </div>
                  )
                })}
                {logRefresh && (<AlwaysScrollToBottom />)}
              </div>

            </div>
          )}

          {selectedClientTab === 'client' && (
            <div className='clientLogBox'>
              <div style={{ borderBottom: '1px solid #acacac', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <p>Client Logs:</p>
                <div style={{ display: 'flex', fontSize: '12px' }}>
                  <input
                    style={{ cursor: 'pointer' }}
                    checked={logRefresh}
                    onChange={(e) => setLogRefresh(e.target.checked)}
                    type='checkbox'
                  />
                  <p style={{ margin: '3px 5px 0px 2px' }}>Refresh</p>
                  <input
                    checked={toggleTimestamp}
                    style={{ cursor: 'pointer' }}
                    onChange={(e) => setToggleTimestamp(e.target.checked)}
                    type='checkbox'
                  />
                  <p style={{ margin: '3px 5px 0px 2px' }}>Timestamps</p>
                </div>
              </div>
              <div style={{ fontSize: '11px', overflow: 'auto', height: 'calc(100% - 16px)' }}>
                {clientLogs.map((log, index) => {
                  return (
                    <div key={index}>
                      <p>{toggleTimestamp &&
                        (<span style={{ color: '#3aa3f5' }}>{log.timestamp}: </span>)}
                        {log.log}
                      </p>
                    </div>
                  )
                })}
                {logRefresh && (<AlwaysScrollToBottom />)}
              </div>

            </div>
          )}

          {selectedClientTab === 'reboot' && (
            <div style={{ margin: '7px 10px' }}>
              <p>Reboot client '{client.Name}'?</p>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <button
                  className='clientListSearchField'
                  type='submit'
                  onClick={(e) => { submitRebootClient() }}
                  style={{ cursor: 'pointer', marginTop: '10px' }}
                >
                  Reboot
                </button>
              </div>
            </div>
          )}

          {selectedClientTab === 'delete' && (
            <div style={{ margin: '7px 10px' }}>
              <p>Delete client '{client.Name}' and all of its associated schedules?</p>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <button
                  className='clientListSearchField'
                  type='submit'
                  onClick={(e) => { submitDeleteClient() }}
                  style={{ cursor: 'pointer', marginTop: '10px' }}
                >
                  DELETE
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

const DisplayImage = ({ src, thumbnail, height, width }) => {
  const maxHeight = height ? height : '90px'
  const maxWidth = width ? width : '160px'
  return (
    <div style={{
      maxHeight: maxHeight,
      maxWidth: maxWidth,
      overflow: 'hidden',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <a
        href={src}
        target='_blank'
        rel="noopener noreferrer"
        aria-label='link'
      >
        <img
          style={{
            objectFit: 'contain',
            maxHeight: maxHeight,
            maxWidth: maxWidth,
            color: 'white',
            textDecoration: 'underline',
            marginTop: '7px'
          }}
          src={thumbnail ? thumbnail : src}
          title=""
          alt='Link'
        />
      </a>
    </div>
  )
}